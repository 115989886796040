import "../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.1_@types+node@18.19.5_webpack@5.91.0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fstyles%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA23S227CMAwA0He%2BwlKeQbk5Tc0Tleh%2F9JLuBoNdgU3798mdJjlR46cepXbsZHM6nG6Xs4bvFcB6%2FfdlCJSNNjqzFWoJ1DQvqY5ADYlDqidQ%2B2bftHupSKB84xu0UgPnrYOpeqkVgWqbaGqUGrmaHvSQaU2g9LykdpzXT3HK8vYEytWu85XUgdU79FnHI4GqXOxrLzXxdIw1tpY6LU7njjVxSL3n3nbtrm2lPizO4XFxDk9zNeyrrIsDgRoHDqlHApUwYcpO9swZkEPq6V87qWeeehjCmE3nhUDhiClk1V65WsCuyva%2BzZPsEEep74tz%2BOC7cM757DY%2FF9%2FOZfGdXQn0%2BSrlRhBz%2BSIwNiejCUwozBDYIpmxBNYX5ghsUcJ4Al%2F%2BiwRY1g0ERpcbqxkzinyWnGqCWJbtuLVQHrAn0BuXjtvVzy9J5bov9QMAAA%3D%3D%22%7D"
import "../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.1_@types+node@18.19.5_webpack@5.91.0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fblocks%2FTrial%2Ftrial.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA31T0W6jMBB8z1esVJ2USHWFnUA49%2BX%2B5OTghVhnbMs4Ce0p%2F37Chh7QtC%2BA7ZnZ8bD78ju%2FusBOGfzdAJxE9afx9mIkqay2nsNV%2BC0hVtu3m6t3r5v75mVk0MhwtlNBWcPBoxZBXXGOYSuMOHVWXwK%2BbgDeiTISew5sWAXrOBCW%2FRgWNyXDmcO40lgHDix%2Bt8qQ8ZTSzPXzYvsvDX0o0ixJzu6pWtEgB60MCk8aL6RCE7bBwsmGYNtneMLDvsrLZ3iq6%2BJnud9FBeslejLALx0HWgxeAJyQUpmGA2WZ6yEfHpTt06G9oq%2B1vXE4KynRzM0fvjG%2FTEqqzmnxxqHWGGWHN5HKY5WoldWX1gwnQqvGEBWw7ThUaAL6ec081qytCaRT7zgEnowOYZAzquYcOOxZ2oy427iZZ1n8a9gHErwwXW19y%2BHiHPpKdNF2K3yjDEkxciiTTKREY48sFdHSijk5%2BJ56fESlNFFnfcPKVd%2BUkfhFvz%2BuukiNHpLerxalErBtRT%2FVOhZH1%2B%2Bi%2FnJsPjrl%2FyWPyel9hmUjNg0HzWPvTgORrdH5iP5kbQkrRtgqKTYmteBPya9bovwse5xkZ0ln2YR7mM5hOF%2Bms7pxmtb75v4P2QP6%2BKcEAAA%3D%22%7D"
export var accentStyle = '_5vpt2b5';
export var buttonStyle = '_5vpt2b7';
export var containerStyle = '_5vpt2b1';
export var contentStyle = '_5vpt2b4';
export var duckStyle = '_5vpt2b2';
export var noteStyle = '_5vpt2b8';
export var rootStyle = '_5vpt2b0';
export var titleStyle = '_5vpt2b6';
export var trialStyle = '_5vpt2b3';