import React from "react";
import Image from "next/image";

import { Block } from "@/blocks/base";
import { BlockContainer } from "@/blocks/base/block-container";
import { FlexContainer } from "@/uikit/container";
import { Button } from "@/blocks/tracking";
import { Typography } from "@/uikit/Typography";

import {
  rootStyle,
  duckStyle,
  containerStyle,
  trialStyle,
  contentStyle,
  accentStyle,
  titleStyle,
  buttonStyle,
  noteStyle,
} from "./trial.css";
import { RemoteImage, parseSizes } from "@/uikit/remote-image";

const bgSizes = {
  mobile: "100vw",
  tablet: "100vw",
  desktop: "100vw",
};

const Trial = Block("trial", ({ accent, settings, title, actionBtn, topLeftImg, trialNote }) => {
  return (
    <BlockContainer settings={settings} defaultTheme="light" className={rootStyle}>
      <FlexContainer className={containerStyle}>
        <div className={duckStyle}>
          <RemoteImage image={topLeftImg} className={duckStyle} />
        </div>
        <div className={trialStyle}>
          <Image sizes={parseSizes(bgSizes)} src="/assets/artwork_fastlines.png" alt="fastlines" fill />
          <div className={contentStyle}>
            <Typography className={accentStyle}>{accent}</Typography>
            <Typography className={titleStyle} settings={{ tag: "h2" }}>
              {title}
            </Typography>
            <Button
              variant={actionBtn.variant}
              size={actionBtn.size}
              fontSize={actionBtn.fontSize}
              fontWeight={actionBtn.fontWeight}
              className={buttonStyle}
              to={actionBtn.link}
              eventName={actionBtn.trackingEvent}
            >
              {actionBtn.text}
            </Button>
            <Typography settings={{ tag: "p" }} className={noteStyle}>
              {trialNote}
            </Typography>
          </div>
        </div>
      </FlexContainer>
    </BlockContainer>
  );
});

export default Trial;
